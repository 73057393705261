import getRoutes from 'Routes/routes';

const listMenu = [
    {
        heading: 'User Management',
        translate: 'sidebar.heading.UserProfileManagement',
        paths: ['/user'],
    },
    {
        name: 'User',
        path: '/user',
        icon: 'fas fa-user',
        translate: 'sidebar.nav.UserProfileManagement.User',
    },

    {
        heading: 'Zone Management',
        translate: 'sidebar.heading.ZoneManagement',
        paths: ['/zone'],
    },
    {
        name: 'Zone',
        path: '/zone',
        icon: 'fas fa-grip-horizontal',
        translate: 'sidebar.nav.BranchManagement.ZoneList',
    },
    {
        heading: 'Branch Management',
        translate: 'sidebar.heading.BranchManagement',
        paths: ['/branch', '/line-group'],
    },
    {
        name: 'Branch',
        path: '/branch',
        icon: 'fas fa-store',
        translate: 'sidebar.nav.BranchManagement.BranchList',
    },
    {
        name: 'Line Group',
        path: '/line-group',
        icon: 'fab fa-line',
        translate: 'sidebar.nav.UserProfileManagement.User',
    },
    {
        heading: 'Machine Management',
        translate: 'sidebar.heading.MachineManagement',
        paths: ['/machine'],
    },
    {
        name: 'Machine',
        path: '/machine',
        icon: 'fas fa-cogs',
        translate: 'sidebar.nav.MachineManagement.MachineList',
    },

    {
        heading: 'Task Management',
        translate: 'sidebar.heading.TaskManagement',
        paths: ['/task-service', '/task-refund', 'task-no-tech'],
    },
    {
        name: 'Task Service',
        path: '/task-service',
        icon: 'fas fa-tasks',
        translate: 'sidebar.nav.TaskManagement.TaskServiceList',
    },
    {
        name: 'Task Refund',
        path: '/task-refund',
        icon: 'fas fa-money-bill-wave',
        translate: 'sidebar.nav.TaskManagement.TaskRefundList',
    },
    {
        name: 'Task No-Tech',
        path: '/task-no-tech',
        icon: 'fas fa-users-slash',
        translate: 'sidebar.nav.TaskManagement.TaskRefundList',
    },
    {
        heading: 'Job Management',
        translate: 'sidebar.heading.JobManagement',
        paths: ['/job', '/planning'],
    },
    {
        name: 'Job',
        path: '/job',
        icon: 'fas fa-list',
        translate: 'sidebar.nav.JobManagement.JobList',
    },
    {
        name: 'Planning',
        path: '/planning',
        icon: 'fas fa-calendar-day',
        translate: 'sidebar.nav.JobManagement.JobPlanning',
    },
    {
        heading: 'Issue Management',
        translate: 'sidebar.heading.Master',
        paths: ['/issueCode', '/listOfValue'],
    },
    {
        name: 'Issue',
        path: '/issueCode',
        icon: 'fas fa-grip-horizontal',
        translate: 'sidebar.nav.Master.issueCodeList',
    },
    {
        name: 'List Of Value',
        path: '/listOfValue',
        icon: 'fas fa-grip-horizontal',
        translate: 'sidebar.nav.Master.listOfValueList',
    },

];

const checkAvailability = (arrA = [], arrB = []) => {
    const result = arrA.filter((x) => arrB.includes(x));
    return result.length > 0 ? true : false;
};

const myPaths = getRoutes().map(function (item) {
    return item['path'];
});

const getMenu = () =>
    listMenu.filter((item) => {
        if (item.paths && item.paths.length > 0 && checkAvailability(myPaths, item.paths)) return true;
        else if (item.path && myPaths.includes(item.path)) return true;
        else return false;
    });

export default getMenu;
